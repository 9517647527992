<script setup lang="ts">
import InfoHref from '~/components/shared/Common/InfoHref.vue';

const config = useRuntimeConfig();
const personalDataFileUrl = config.public.personalDataFileUrl;
const cookiePolicyUrl = config.public.cookiePolicyUrl;
const errorTextEmail = config.public.errorTextEmail;

const route = useRoute();

// URL'ы на которых должно отображаться согласие на обработку персональных данных
const personalDataVisibleRoutes = [ '/users/' ];

// Вычисляемое свойство для проверки видимости
const isPersonalDataVisible = computed(() => {
    return personalDataVisibleRoutes.some(r => 
        route.path.indexOf(r) >= 0
    );
});
</script>

<template>
    <div class="footer">
        <div class="content">
            <div class="policy">
                <div>
                    <a href="https://kortros.ru/politika-obrabotki-personalnyh-dannyh.pdf" target="_blank">{{ $t('forms.shared.phrases.personalPolicy') }}</a>
                </div>
                <div>
                    <InfoHref
                        :fileType="'txt'"
                        :dialogTitle="$t('forms.shared.phrases.cookiePolicy')"
                        :fileUrl="cookiePolicyUrl"
                    >
                    </InfoHref>
                </div>
                <div v-if="isPersonalDataVisible">
                    <a :href="personalDataFileUrl" target="_blank">{{ $t('forms.shared.phrases.personalData') }}</a>
                </div>
                <div>
                    <span class="feedback">
                        {{ $t('forms.shared.phrases.feedback') }} <a :href="`mailto: ${errorTextEmail}`" class="link">{{ errorTextEmail }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .footer {
        display: flex;
        width: 100%;

        .content{
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            width: 100%;

            color: var(--Color-Zink-400, $main-text-color);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.13px;
            gap: 16px;

            .policy {
                display: flex;
                gap: 16px;

                @include responsive( 'mobile' ) {
                    flex-direction: column;
                    gap: var(--Padding-pa-8, 32px);
                    padding: 0 20px;
                    text-align: center;
                    align-items: center;
                }

                .feedback {
                    color: var(--Color-Zink-400, $main-text-color);

                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.13px;

                    .link {
                        color: var(--Color-Blue-600, $link-color);

                        font-size: 13px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: -0.13px;
                    }
                }
            }

            @include responsive( 'mobile' ) {
                flex-direction: column;
                gap: var(--Padding-pa-8, 32px);
                padding: 0px 20px;
                text-align: center;
            }
        }
    }
</style>
